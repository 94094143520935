export default {
  verify: {
    title: "Let's verify your account!",
    subtitle:
      'To keep your account secure, please enter the verification code sent to your email.',
    code: 'Verification code',
    verifyButton: 'Submit code',
    resendMessage:
      "Didn't receive code? It may take up to two minutes to arrive.",
    resendVerificationButton: 'Resend verification code',
    goBackTo: 'Go back to',
    needNewAccount: 'Need a new account?',
    signIn: 'Sign in',
    signUp: 'Sign up',
  },
};
